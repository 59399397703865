import React, {useState, useEffect} from 'react';
import './index.css';
import {useLocation} from 'react-router-dom';
import serviceInfo from '../../data/services.json';
import Consultation from './Consultation';
import CloudMigration from './CloudMigration';
import LicenseManagement from './LicenseManagement';
import CustomDevelopment from './CustomDevelopment';
import Support from './Support';
import TestAutomation from './TestAutomation';
import WorkflowAutomation from './WorkflowAutomation';

const Services = () => {
  // GLOBAL
  const location = useLocation();

  // USESTATE
  const [service, setService] = useState('');

  // USEEFFECT
  useEffect(() => {
    const path = location?.pathname?.split('/');
    const _service = path[path?.length - 1];
    setService(_service);
  }, []);
  return (
    <div className='ag__services-main-container'>
      {service === 'consultation' &&
      <Consultation info={serviceInfo?.consultation}/>}
      {service === 'supportaugmentation' &&
      <Support info={serviceInfo?.support}/>}
      {service === 'cloudmigration' &&
      <CloudMigration info={serviceInfo?.cloudmigration}/>}
      {service === 'licensemanagement' &&
      <LicenseManagement info={serviceInfo?.cloudmigration}/>}
      {service === 'customdevelopment' &&
      <CustomDevelopment info={serviceInfo?.customdevelopment}/>}
      {service === 'workflowautomation' &&
      <WorkflowAutomation info={serviceInfo?.cloudmigration}/>}
      {service === 'testautomation' &&
      <TestAutomation info={serviceInfo?.cloudmigration}/>}
    </div>
  );
};

export default Services;
